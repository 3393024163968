import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const limitString = (string, limit) => {
  if (!limit || !string || string.length <= limit) {
    return string
  }

  return `${string.slice(0, limit).trim()}...`
}

export const formatCurrency = value => {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

const limit = (value, max) => {
  if (value.length === 1 && value[0] > max[0]) value = '0' + value
  if (value.length === 2 && value > max) value = max

  return value
}

export const timeValidation = value => {
  const hour = limit(value.substring(0, 2), '23')
  const minutes = limit(value.substring(2, 4), '59')

  return hour + (minutes.length ? ':' + minutes : '')
}

export const formatCPFToNumber = value => value.replace(/\D/g, '')

export const formatName = name => {
  if (typeof name !== 'string') return ''
  return name
    .toLowerCase()
    .split(/\s/)
    .reduce((acc, currentName) => {
      if (!currentName) return acc
      const captilizedName = currentName.charAt(0).toUpperCase() + currentName.slice(1)
      return `${acc} ${captilizedName}`.trim()
    }, '')
}

export const formatDate = date => {
  if (!date) {
    return undefined
  }
  const newDate = new Date(date)
  return new Intl.DateTimeFormat('pt-BR').format(new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000))
}

export const formatPhoneWithoutPrefix = number => {
  const phonePattern = /(\d{4}\d?)(\d{4})/
  return number ? number.replace(phonePattern, '$1-$2') : '-'
}

export const sanitizeMoney = value => Number(value.replace(/\./g, '').replace(',', '.').replace(/^\D*/, ''))

export const formatPhone = (prefix, phone) => {
  return `(${prefix}) ${phone.substr(0, 1)} ${phone.substr(1, 4)}-${phone.substr(5, 8)}`
}

export const formatCPF = value => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCNPJ = value => {
  return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatCEP = value => {
  return value.replace(/^([\d]{2})\.?([\d]{3})\-?([\d]{3})/, '$1$2-$3')
}

export const remainingDays = value => {
  if (value) {
    const valueTime = new Date(parseISO(value))
    const diff = valueTime - new Date()
    const differenceInDays = Math.round(diff / (24 * 60 * 60 * 1000))
    return differenceInDays < 0 ? 0 : differenceInDays
  }
}

export const calculatePercent = (maxValue, currentAmount) => {
  let percent = (currentAmount * 100) / maxValue
  if (percent > 100) return 100
  return percent.toFixed(2)
}

export const formatAnnualRate = value => {
  if (isNaN(parseFloat(value))) {
    return 0
  } else {
    return parseFloat(((parseFloat(value) / 100 + 1) ** 12 - 1) * 100).toFixed(2)
  }
}

export const numberOfDays = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000

  return Math.round(Math.abs((date1 - date2) / oneDay))
}

export const convertUTCToDate = date => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  return newDate
}

export const convertUTCToDateSubmit = date => {
  var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
  return newDate
}

export const convertDateToOpening = dat => {
  var date = new Date(dat)
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  return newDate.toISOString()
}

export const bmpFormatNumber = value => (isNaN(value) ? Number(value.replace(',', '.')) : value)

export const formatToDate = value => new Date(value.replace(/\//g, '-').replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))

export const formatTax = number => String(number).replace('.', ',') + '%'

export const testFilter = obj => {
  const value = Object.entries(obj)
    .map(item => {
      if (item[0] != 'all') {
        return item[1] ? 1 : 0
      } else {
        return 0
      }
    })
    .reduce((previousValue, currentValue) => previousValue + currentValue)
  return value > 1 ? true : false
}
